import React, { useState } from "react";
import "./Portfolio.css";
import Header from "../components/Header";
import ImageSlider from "../components/ImageSlider";
import { useLanguage } from "../LanguageContext";
import { useNavigate } from "react-router-dom";

const Portfolio = () => {

  const { selectedLanguage, changeLanguage } = useLanguage(); // LanguageContext에서 상태와 함수 가져오기

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage); // 언어 변경 함수 호출
  };

  const aboutNursense = () => {
    window.open(
      "https://nursense.kr/"
    );
  };

  const aboutSimin = () => {
    window.open("https://cianote.vercel.app/main");
  };

  const jenanVideo = () => {
    alert('준비중입니다.')
  };

  const fireVideo = () => {
    window.open(
      "https://drive.google.com/file/d/1KfkGF70HWao3N1ixASJtTDYzWII4xte-/view?usp=drive_link"
    );
  };

  const [isImageSliderOpen, setImageSliderOpen] = useState(false);
  const navigate = useNavigate();

  const openImageSlider = () => {
    setImageSliderOpen(true);
  };

  const closeImageSlider = () => {
    setImageSliderOpen(false);
  };

  const images = [
    `${process.env.PUBLIC_URL}/img/pfimg/edu1.jpg`,
    `${process.env.PUBLIC_URL}/img/pfimg/edu2.jpg`,
    `${process.env.PUBLIC_URL}/img/pfimg/edu3.jpg`,
    `${process.env.PUBLIC_URL}/img/pfimg/edu4.jpg`,
    `${process.env.PUBLIC_URL}/img/pfimg/edu5.jpg`,
    `${process.env.PUBLIC_URL}/img/pfimg/edu6.jpg`,
    `${process.env.PUBLIC_URL}/img/pfimg/edu7.jpg`,
  ];

  const goToPortDetail = () => {
    navigate('/portfolio/detail')
  }

  return (
    <>
      <div className="portfolioContainer">
        <Header onLanguageChange={handleLanguageChange} />
        <div className="portfolioWrapper">
          <div className="portfolioContentWrapper">
            <div className="nursenseWrapper">
              <h1>{selectedLanguage === "ko" ? (
              '널센스'
            ) : (
              'Nursense'
            )}</h1>
              <img
                onClick={aboutNursense}
                src={`${process.env.PUBLIC_URL}/img/pfOpen.png`}
                alt="Img"
              />
              <span>{selectedLanguage === "ko" ? (
              '간호학과 학생들의 임상술기 체험 메타버스 플랫폼'
            ) : (
              'Metaverse Platform for Nursing Students Clinical Procedure Experience'
            )}</span>
            </div>
            <div className="portfolioMiddleWrapper">
              <div className="siminWrapper">
                <h1>{selectedLanguage === "ko" ? (
              '안전교육'
            ) : (
              'Safety Education'
            )}</h1>
                <img
                  onClick={aboutSimin}
                  src={`${process.env.PUBLIC_URL}/img/pfOpen.png`}
                  alt="Img"
                />
                <span>
                {selectedLanguage === "ko" ? (
              '각종 재난 발생시 안전체험교육을 통하여 사고를 미연에 방지하는\n메타버스 안전교육'
            ) : (
              'Virtual Reality Safety Education to Prevent Accidents through \n Safety Experience Education in Various Disasters'
            )}
                </span>
              </div>
              <div className="jenanWrapper">
                <h1>{selectedLanguage === "ko" ? (
              '재난안전'
            ) : (
              'Disaster Safety'
            )}</h1>
                <img
                  src={`${process.env.PUBLIC_URL}/img/pfOpen.png`}
                  alt="Img"
                  onClick={jenanVideo}
                />
                <span>{selectedLanguage === "ko" ? (
              '메타버스로 체험하는 재난 발생시 간호사의 행동요령'
            ) : (
              'Actions for Nurses in Case of Disaster Experience in the Metaverse'
            )}</span>
              </div>
            </div>
            <div className="portfolioMiddleWrapper">
              <div className="fireWrapper">
                <h1>{selectedLanguage === "ko" ? (
              '화재안전'
            ) : (
              'Fire Safety'
            )}</h1>
                <img
                  src={`${process.env.PUBLIC_URL}/img/pfOpen.png`}
                  onClick={fireVideo}
                  alt="Img"
                />
                <span>{selectedLanguage === "ko" ? (
              '메타버스로 체험하는 가정에서 화재발생시 대피요령'
            ) : (
              'Evacuation Procedures in Case of Fire at Home Experienced Through Metaverse'
            )}</span>
              </div>
              <div className="eduWrapper">

                <h1>{selectedLanguage === "ko" ? (
              '메타버스 교육'
            ) : (
              'Metaverse Education'
            )}</h1>
                <img
                  src={`${process.env.PUBLIC_URL}/img/pfOpen.png`}
                  onClick={openImageSlider}
                  alt="Img"
                />
                <span>{selectedLanguage === "ko" ? (
              'VR체험, ZEPETO, ZEP 등 메타버스 교육 프로그램'
            ) : (
              'VR Experience, ZEPETO, ZEP, and More'
            )}</span>
              </div>
            </div>
          </div>
          <div className="portfolioMoreWrapper">
            <button onClick={goToPortDetail}>More →</button>
          </div>
        </div>
        {isImageSliderOpen && (
          <ImageSlider images={images} onClose={closeImageSlider} />
        )}
      </div>
    </>
  );
};

export default Portfolio;