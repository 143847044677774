import React, { useEffect, useState } from "react";
import "./Introduce.css";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageContext";

const Introduce = () => {
  const { selectedLanguage, changeLanguage } = useLanguage(); // LanguageContext에서 상태와 함수 가져오기

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage); // 언어 변경 함수 호출
  };

  const navigate = useNavigate();

  const goToEq = () => {
    window.open("/equipment", "_blank");
  };

  const goToCerti = () => {
    window.open("/certification", "_blank");
  };

  const goToContact = () => {
    window.open("/contact", "_blank");
  };

  const goToContent = () => {
    window.open("/content", "_blank");
  };

  return (
    <>
      <div className="introContainer">
        <Header onLanguageChange={handleLanguageChange} />
        <div className="introWrapper">
          <div className="introTextWrapper">
            <p>
              {" "}
              {selectedLanguage === "ko"
                ? "더블엠소셜컴퍼니"
                : "DoubleM Social Company"}
            </p>
            <p>DoubleM Social Company</p>
            <p>
              {selectedLanguage === "ko"
                ? "더블엠소셜컴퍼니는"
                : "DoubleM Social Company is"}
              <br />
              {selectedLanguage === "ko"
                ? "메타버스 플랫폼을 기반으로"
                : "developing various educational contents based"}
              <br />
              {selectedLanguage === "ko"
                ? "다양한 교육 컨텐츠들을 개발하고 있습니다."
                : "on the metaverse platform."}
            </p>
          </div>
          <div className="introContentsWrapper1">
            <div className="introContentsDiv1">
              <img src={`${process.env.PUBLIC_URL}/img/certi.png`} alt="img" />
              <p>
                {" "}
                {selectedLanguage === "ko"
                  ? "다양한 특허 및 인증"
                  : "Various patents and certifications"}
              </p>
              <p>
                {selectedLanguage === "ko"
                  ? "국내외 특허인증"
                  : "Domestic and international patent certifications"}
              </p>
              <button onClick={goToCerti} className="viewMoreButton">
                {selectedLanguage === "ko" ? "더 보기 →" : "VIEW MORE →"}
              </button>
            </div>
            <div className="introContentsDiv2">
              <img src={`${process.env.PUBLIC_URL}/img/hand.png`} alt="img" />
              <p>
                {" "}
                {selectedLanguage === "ko"
                  ? "파트너쉽 문의 및 협업문의"
                  : "Partnership inquiries and collaboration requests"}
              </p>
              <p>
                {selectedLanguage === "ko"
                  ? "투자관련 등"
                  : "Investment-related"}
                <br />
                {selectedLanguage === "ko"
                  ? "파트너쉽 문의"
                  : "Partnership Inquiry"}
              </p>
              <button onClick={goToContact} className="viewMoreButton">
                {selectedLanguage === "ko" ? "더 보기 →" : "VIEW MORE →"}
              </button>
            </div>
          </div>
          <div className="introContentsWrapper2">
            <div className="introContentsDiv1">
              <img src={`${process.env.PUBLIC_URL}/img/media.png`} alt="img" />
              <p>
                {" "}
                {selectedLanguage === "ko"
                  ? "다양한 콘텐츠 및 장비"
                  : "Various Contents and Equipment"}
              </p>
              <p>
                {selectedLanguage === "ko"
                  ? "VR기반 콘텐츠"
                  : "VR-based Content"}
                <br />
                {selectedLanguage === "ko"
                  ? "립모션, MetaQuest, Vive 등"
                  : "LeapMotion, MetaQuest, Vive and more"}
              </p>
              <button onClick={goToEq} className="viewMoreButton">
                {selectedLanguage === "ko" ? "더 보기 →" : "VIEW MORE →"}
              </button>
            </div>
            <div className="introContentsDiv2">
              <img src={`${process.env.PUBLIC_URL}/img/code.png`} alt="img" />
              <p>
                {" "}
                {selectedLanguage === "ko"
                  ? "각종 콘텐츠 개발"
                  : "Development of Various Contents"}
              </p>
              <p>
                {selectedLanguage === "ko"
                  ? "메타버스 특수교육 콘텐츠"
                  : "Metaverse Special Education Contents"}
                <br />
                {selectedLanguage === "ko"
                  ? "메타버스 플랫폼 개발"
                  : "Metaverse Platform Development"}
              </p>
              <button onClick={goToContent} className="viewMoreButton">
                {" "}
                {selectedLanguage === "ko" ? "더 보기 →" : "VIEW MORE →"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduce;
