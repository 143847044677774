import React, { useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageContext"; // LanguageContext에서 언어 상태를 가져옴

const Header = ({ isContentPage, onLanguageChange }) => {
  const navigate = useNavigate();
  const { selectedLanguage } = useLanguage(); // LanguageContext에서 언어 상태를 가져옴
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const goToMain = () => {
    navigate("/");
    closeMenu();
  };

  const goToIntroduce = () => {
    navigate("/introduce");
    closeMenu();
  };

  const goToContact = () => {
    navigate("/contact");
    closeMenu();
  };

  const goToContent = () => {
    navigate("/content");
    closeMenu();
  };

  const goToPortfolio = () => {
    navigate("/portfolio");
    closeMenu();
  };

  const goToLogin = () => {
    navigate("/login");
    closeMenu();
  };

  const goToCerti = () => {
    navigate("/certification");
    closeMenu();
  };

  const goToEquip = () => {
    navigate("/equipment");
    closeMenu();
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const handleLanguageChange = (newLanguage) => {
    onLanguageChange(newLanguage); // 부모 컴포넌트로 언어 변경 알림
    setIsLanguageMenuOpen(false); // 언어 변경 후 언어 메뉴 닫기
  };

  // 선택한 언어에 따라 텍스트를 번역하는 함수
  const translateText = (key) => {
    // 번역 기능 구현
  };

  return (
    <>
      <div
        className={`headerContainer ${isContentPage ? "contentHeader" : ""}`}
      >
        <div className="headerWrapper">
          <div className="logoWrapper">
            <img
              src={process.env.PUBLIC_URL + "/img/dm_logo_white.png"}
              alt="logoImage"
              onClick={goToMain}
            />
          </div>
          <div className="navWrapper">
            <span onClick={goToIntroduce}>
              {" "}
              {selectedLanguage === "ko" ? "회사소개" : "About Us"}
            </span>
            <span onClick={goToContent}>
              {" "}
              {selectedLanguage === "ko" ? "비지니스" : "Business"}
            </span>
            <span onClick={goToPortfolio}>
              {" "}
              {selectedLanguage === "ko" ? "포트폴리오" : "Portfolio"}
            </span>
            <span onClick={goToContact}>
              {" "}
              {selectedLanguage === "ko" ? "문의하기" : "Contact"}
            </span>
          </div>
          <div className="loginHeaderWrapper">
            <div className="mobileMenuWrapper">
              <img
                src={process.env.PUBLIC_URL + "/img/mobileMenu.png"}
                alt="menuIcon"
                className="mobileMenuImg"
                onClick={toggleMenu}
              />
            </div>
            <div className="lanWrapper">
              <span onClick={toggleLanguageMenu}>Language</span>
              {isLanguageMenuOpen && (
                <div className="languageMenu">
                  <div
                    onClick={() => handleLanguageChange("ko")}
                    className="koreanWrapper"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/korea.png`}
                      alt="img"
                    />
                    <span>한국어</span>
                  </div>
                  <div>
                    <div onClick={() => handleLanguageChange("en")}>
                      <img
                        src={`${process.env.PUBLIC_URL}/img/usa.png`}
                        alt="img"
                      />
                      <span>English</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className={`mobileMenu ${isMenuOpen ? "open" : ""}`}>
          <div className="mobileMenuLogoWrapper">
            <div className="mobileLogoWrapper">
              <img
                src={process.env.PUBLIC_URL + "/img/dm_logo.png"}
                alt="menuLogo"
                onClick={goToMain}
              />
            </div>
            <div className="mobileXButtonWrapper">
              <img
                src={process.env.PUBLIC_URL + "/img/xButton_black.png"}
                alt="mobileXButton"
                onClick={toggleMenu}
                className="mobileXButton"
              />
            </div>
          </div>
          <div className="mobileOpenWrapper">
            <span onClick={goToMain}>
              {" "}
              {selectedLanguage === "ko" ? "홈" : "Home"}
            </span>
            <span onClick={goToContent}>
              {" "}
              {selectedLanguage === "ko" ? "비즈니스" : "Business"}
            </span>
            <span onClick={goToCerti}>
              {" "}
              {selectedLanguage === "ko" ? "특허 및 인증" : "Certification"}
            </span>
            <span onClick={goToEquip}>
              {" "}
              {selectedLanguage === "ko" ? "장비" : "Equipment"}
            </span>
            <div className="mobileLanguageWrapper">
              <p>Language</p>
              <img
                onClick={() => handleLanguageChange("ko")}
                src={process.env.PUBLIC_URL + "/img/korea.png"}
                alt="mobileKorean"
              />
              <img
                onClick={() => handleLanguageChange("en")}
                src={process.env.PUBLIC_URL + "/img/usa.png"}
                alt="mobileKorean"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
