import React, { useState } from "react";
import "./ImageSlider.css"; // 스타일링을 위한 CSS 파일

const ImageSlider = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleOverlayClick = (e) => {
    // 모달창 내부를 클릭한 경우에만 모달창을 닫도록 합니다.
    if (e.target.className === "imageSliderOverlay") {
      onClose();
    }
  };

  return (
    <div className="imageSliderOverlay" onClick={handleOverlayClick}>
      <div className="imageSliderContainer">
        <button onClick={goToPrevSlide}>&lt;</button>
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        <button onClick={goToNextSlide}>&gt;</button>
      </div>
    </div>
  );
};

export default ImageSlider;
