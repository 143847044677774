import React from 'react';
import './Portfolio2.css';
import Header from '../components/Header';

const Portfolio2 = () => {
  // goToGumi 함수를 먼저 정의합니다.
  const goToGumi = () => {
    window.open('https://zep.us/play/DvN7Kg', '_blank');
  };

  // 예시로 제목과 이미지 URL을 가진 배열
  const portfolioItems = [
    { title: '2024 구미정신건강복지센터', imageUrl: '/img/list1.jpg', onClick: goToGumi },
    { title: '2022 제 19회 영천 보현산 별빛축제', imageUrl: '/img/list2.jpg' },
    { title: '제주의 섬', imageUrl: '/img/list3.jpg' },
    { title: '기상청 기후과학공모전전시회 달콤기후', imageUrl: '/img/list4.jpg' },
    { title: '도레미 파티하우스', imageUrl: '/img/list5.jpg' },
    { title: '카페 프로방스', imageUrl: '/img/list6.jpg' },
    { title: '파리의 기차역', imageUrl: '/img/list7.jpg' },
    { title: '그랜드호텔', imageUrl: '/img/list8.jpg' },
    { title: '올림픽 주경기장', imageUrl: '/img/list9.jpg' },
    // 추가적인 아이템들...
  ];

  return (
    <>
      <div className='portfolio2Container'>
      <Header />
        <div className='portfolio2Wrapper'>
          <div className='portfolio2TitleWrapper'>
            <p className='portfolio2Title'>Portfolio</p>
          </div>
          <div className='detailWrapper'>
            {/* 배열을 맵핑하여 각 항목을 렌더링 */}
            {portfolioItems.map((item, index) => (
              <div key={index} className='portfolioItem' onClick={item.onClick}>
                <img src={item.imageUrl} alt={item.title} />
                <p>{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio2;
