import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LanguageProvider } from "./LanguageContext";
import { Analytics } from '@vercel/analytics/react';
import "./App.css";
import Main from "./page/Main";
import Introduce from "./page/Introduce";
import Equipment from "./page/Equipment";
import Certification from "./page/Certification";
import Contact from "./page/Contact";
import Content from "./page/Content";
import Portfolio from "./page/Portfolio";
import Login from "./page/Login";
import Register from "./page/Register";
import Portfolio2 from "./page/Portfolio2";

function App() {
  return (
    <LanguageProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/introduce" element={<Introduce />} />
          <Route path="/equipment" element={<Equipment />} />
          <Route path="/certification" element={<Certification />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/content" element={<Content />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/detail" element={<Portfolio2 />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Analytics />
      </div>
    </LanguageProvider>
  );
}

export default App;
