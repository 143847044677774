// Main.js

import React from "react";
import "./Main.css";
import Header from "../components/Header";
import { useNavigate } from 'react-router-dom';
import Introduce from "./Introduce";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import { useLanguage } from "../LanguageContext";

const Main = () => {
  const navigate = useNavigate();
  const { selectedLanguage, changeLanguage } = useLanguage(); // LanguageContext에서 상태와 함수 가져오기

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage); // 언어 변경 함수 호출
  };

  return (
    <>
      <div className="mainContainer">
        <div className="mainBgWrapper">
          <div className="backgroundVideo">
            <video autoPlay loop muted playsInline className="video">
              <source
                src={`${process.env.PUBLIC_URL}/video/mainVideo.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <Header onLanguageChange={handleLanguageChange} />
          <div className="mainTextWrapper">
            <p>DoubleM Social Company</p>
            {selectedLanguage === "ko" ? (
              <p>메타버스 세상을 여는 기업, 더블엠소셜컴퍼니</p>
            ) : (
              <p>The company that opens the metaverse world, DoubleM Social Company</p>
            )}
            <div className="scrollWrapper">
              {/* <img src={`${process.env.PUBLIC_URL}/img/scroll-ani.gif`} /> */}
            </div>
          </div>
        </div>
        <Introduce />
        <Portfolio />
        <Contact />
      </div>
    </>
  );
};

export default Main;
