import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import common_en from './translations/en/common_en.json';
import common_ko from './translations/ko/common_ko.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'ko', // 기본 언어 설정
  resources: {
    en: {
      common: common_en,
    },
    ko: {
      common: common_ko,
    },
  },
});

ReactDOM.render(
  // I18nextProvider로 App을 감싸 한국어 번역을 적용합니다.
  <I18nextProvider i18n={i18next}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </I18nextProvider>,
  document.getElementById('root')
);

reportWebVitals();
