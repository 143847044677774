import React from "react";
import "./Certification.css";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext";

const Certification = () => {

  const { selectedLanguage, changeLanguage } = useLanguage(); // LanguageContext에서 상태와 함수 가져오기

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage); // 언어 변경 함수 호출
  };

  return (
    <>
      <div className="certificationContainer">
        <Header onLanguageChange={handleLanguageChange} />
        <div className="certificationWrapper">
          <div className="certiTextWrapper">
            <p>              {selectedLanguage === "ko" ? (
              '더블엠소셜컴퍼니'
            ) : (
              'DoubleM Social Company'
            )}</p>
            <p>{selectedLanguage === "ko" ? (
              'Patents and Certifications'
            ) : (
              'Patents and Certifications'
            )}</p>
            <p>{selectedLanguage === "ko" ? (
              '다양한 인증 및 특허 출원, 등록'
            ) : (
              'Diverse Certifications and Patents Application and Registration'
            )}</p>
          </div>
          <div className="certiListWrapper1">
            <div className="certiList1">
              <Link
                to={
                  "https://drive.google.com/file/d/10fq1LLyAfzigH5Ia96oBtBrosml9OWkb/view?usp=sharing"
                }
                target="_blank"
              >
                {selectedLanguage === "ko" ? (
              '2022기술평가우수기업인증서.pdf'
            ) : (
              '2022 Technical Evaluation Excellent Company Certification.pdf'
            )}
              </Link>
              <Link
                to={
                  "https://drive.google.com/file/d/1PcG_zBT8O95e_zeAppXzMBfIWOAD_Sj6/view?usp=sharing"
                }
                target="_blank"
              >
                {selectedLanguage === "ko" ? (
              '벤처기업확인서.pdf'
            ) : (
              'Venture Enterprise Confirmation Certificate.pdf'
            )}
              </Link>
              <Link
                to={
                  "https://drive.google.com/file/d/1ZBKXqMQsm6eq9TwNBCxHMzdCQkSDbaW3/view?usp=sharing"
                }
                target="_blank"
              >
                {selectedLanguage === "ko" ? (
              '널센스 저작권 등록증.pdf'
            ) : (
              'Nursense Copyright Registration Certificate.pdf'
            )}
              </Link>
              <Link
                to={
                  "https://drive.google.com/file/d/1tqSFlbWO_nlAG7lhKFpTrMf6IUXNiHLH/view?usp=sharing"
                }
                target="_blank"
              >
                {selectedLanguage === "ko" ? (
              '커스터마이징된 시뮬레이션 환경을 통한'
            ) : (
              'Patent application for customized simulation environment-based education system'
            )}
                <br />
                {selectedLanguage === "ko" ? (
              '교육 시스템 및 교육 방법 특허출원.pdf' 
            ) : (
              'and teaching methods.pdf'
            )}
              </Link>
            </div>
          </div>
          <div className="certiListWrapper2">
            <div className="certiList2">
              <Link
                to={
                  "https://drive.google.com/file/d/13JiVMNLsl1shAdciGQYUztCbHbv2RS69/view?usp=sharing"
                }
                target="_blank"
              >
                {selectedLanguage === "ko" ? (
              '광고특허증.pdf'
            ) : (
              'Advertising Patent Certificate.pdf'
            )}
              </Link>
              <Link
                to={
                  "https://drive.google.com/file/d/1jDQeDNwFv85WujkNiwmuzW0gFAOPS73I/view?usp=sharing"
                }
                target="_blank"
              >
                {selectedLanguage === "ko" ? (
              '전력제어장치특허증.pdf'
            ) : (
              'Power Control Device Patent Certificate.pdf'
            )}
              </Link>
              <Link
                to={
                  "https://drive.google.com/file/d/1qpuixwkCzhzwnLzEyjngVCuLO0WX74T-/view?usp=sharing"
                }
                target="_blank"
              >
                {selectedLanguage === "ko" ? (
              '제어보드 및 모션센서 결합 장치 및 결합 방법 특허출원.pdf'
            ) : (
              'Control Board and Motion Sensor Combination Device and Combination Method Patent Application.pdf'
            )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certification;
